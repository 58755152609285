import { graphql, useStaticQuery } from "gatsby"

export default function useProjects() {
  const data = useStaticQuery(graphql`
    query {
      project: allFile(filter: { sourceInstanceName: { eq: "project" } }) {
        nodes {
          childMdx {
            frontmatter {
              slug
              name
              type
              features
              technologies
            }
          }
        }
      }
      image: allFile(filter: { extension: { eq: "png" } }) {
        nodes {
          name
          publicURL
        }
      }
    }
  `)

  const images = data.image.nodes.reduce(
    (acc, cur) => ({ ...acc, [cur.name]: cur.publicURL }),
    {}
  )

  return data.project.nodes.map(project => {
    const { frontmatter } = project.childMdx
    return Object.assign({}, frontmatter, {
      imageUrl: images[frontmatter.slug],
    })
  })
}
