/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../components/layout"
import ProjectPreview from "../components/project-preview"
import useProjects from "../hooks/use-projects"

export default function Projects() {
  const projects = useProjects()
  return (
    <Layout>
      {projects.map(project => (
        <ProjectPreview key={project.slug} {...project} />
      ))}
    </Layout>
  )
}
