/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Link } from "gatsby"

import Badge from "./badge"
import Card, { CardTitle, CardMute } from "./card"
import ReadMore from "./read-more"

export default function ProjectPreview({
  slug,
  name,
  type,
  technologies,
  imageUrl,
}) {
  return (
    <Card bgImageUrl={imageUrl}>
      <CardMute>{type}</CardMute>
      <CardTitle>
        <Link to={`/projects/${slug}`}>{name}</Link>
      </CardTitle>
      <p css={{ maxWidth: "66.6666%" }}>
        {technologies.map(technology => (
          <Badge key={technology}>{technology}</Badge>
        ))}
      </p>
      <footer>
        <ReadMore to={`/projects/${slug}`} />
      </footer>
    </Card>
  )
}
