/** @jsx jsx */
import { jsx } from "@emotion/core"

export default function Badge({ children }) {
  return (
    <span
      css={{
        fontSize: 14,
        fontStyle: "italic",
        background: "#e0f2f1",
        padding: 8,
        paddingTop: 4,
        paddingBottom: 4,
        marginRight: 8,
        borderRadius: 3,
        marginBottom: 4,
        display: "inline-block",
        lineHeight: "1.25em",
      }}
    >
      {children}
    </span>
  )
}
